        <template>
  <b-card>
    <b-row
          >
                                    <b-col
                                                                                      sm="12"
                                        >
                            <sp-form-profile
            sendApi="https://api.hoglabest.com/api/v1/json/lk/buyer/contract/upd/profile"
            getApi="https://api.hoglabest.com/api/v1/json/lk/buyer/contract/item/profile"
            :apiId="null"
            :loadById="null"
            method="put"
            id="sp-form-profile"
            title="&lt;h4&gt;Личные данные&lt;/h4&gt;"
    />
            </b-col>
                                        <b-col
                                                                                      sm="12"
                                        >
                            <sp-form-address
            sendApi="https://api.hoglabest.com/api/v1/json/lk/buyer/contract/upd/profile/address"
            getApi="https://api.hoglabest.com/api/v1/json/lk/buyer/contract/item/address"
            :apiId="null"
            :loadById="null"
            method="put"
            id="sp-form-profile"
            title="&lt;hr class=&quot;mt-3&quot;&gt;&lt;h4&gt;Адрес&lt;/h4&gt;"
    />
            </b-col>
                                        <b-col
                                                                                      sm="12"
                                        >
                            <sp-form-password
            sendApi="https://api.hoglabest.com/api/v1/json/lk/buyer/contract/upd/profile/pwd"
            getApi=""
            :apiId="null"
            :loadById="null"
            method="put"
            id="sp-form-profile"
            title="&lt;hr class=&quot;mt-3&quot;&gt;&lt;h4&gt;Вход&lt;/h4&gt;"
    />
            </b-col>
                            </b-row>
  </b-card>
</template>
<script>
/* eslint-disable */
// prettier-ignore
import {
  BCard,
  BRow,
  BCol,
} from 'bootstrap-vue'


                                      import SpFormProfile from '@core/components/sp/sp-form/SpFormProfile.vue'
                                                              import SpFormAddress from '@core/components/sp/sp-form/SpFormAddress.vue'
                                                              import SpFormPassword from '@core/components/sp/sp-form/SpFormPassword.vue'
                            
export default {
  components: {
    BCard,
    BRow,
    BCol,

                                                          SpFormProfile,
                                                                              SpFormAddress,
                                                                              SpFormPassword,
                                          },
  setup() {
    
      },
}
</script>
    